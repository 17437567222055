import { WakeLock } from '../modules/wakeLock.js';

const validateFileType = (file) => {
  const type = file.type;
  return type.startsWith('image/') || type.startsWith('video/');
};

function resetProgressBar() {
  $j('#progress .bar').css('width', '0%');
  $j('#progress .bar').html('');
  $j('#progress .bar').hide();
}

function setProgressBar(percentage) {
  $j('#progress .bar').show();
  $j('#progress .bar').css( 'width', percentage + '%' );
  $j('#progress .bar').html( percentage + '%' );
}

function startSpinner() {
  $j('.label-tus-upload i').removeClass('fa-camera');
  $j('.label-tus-upload i').addClass('fa-spin');
  $j('.label-tus-upload i').addClass('fa-spinner-scale');
}

function initializeTus() {
  if ($j('#tus-upload').length) {
    console.log("Loading Tus...");

    const wakeLock = new WakeLock();

    document.getElementById('label-tus-upload').addEventListener('click', function (e) {
      wakeLock.requestWakeLock();
      wakeLock.autoReacquireOnVisibilityChange();
    });

    document.getElementById('tus-upload').addEventListener('change', function (e) {

      $j('#tus-upload').prop("disabled", true);
      $j('#label-tus-upload i').css('color', 'gray');

      var file = e.target.files[0]
      const blogId = e.target.dataset['hash'];

      if (!validateFileType(file)) {
        alert(e.target.dataset['filevalidation']);
        return;
      }
      var upload = new tus.Upload(file, {
        endpoint: '/tus',
        fingerprint: function (file, options) {
          return Promise.resolve(`${file.name}-${blogId}`);
        },
        parallelUploads: 1,
        storeFingerprintForResuming: true,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 5 * 1024 * 1024, // 5Mb
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        onError: function (error) {
          alert('Upload Failed: ' + error);
          resetProgressBar();
          wakeLock.releaseWakeLock();
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(0);
          setProgressBar(percentage);
        },
        onSuccess: function () {
          resetProgressBar();
          startSpinner();
          wakeLock.releaseWakeLock();

          const tusId = upload.url.split('/').pop();
          const tusType = upload.file.type;
          const tusExtension = upload.file.name.split('.').pop();

          var parameters = {
            format: 'js',
            tus_id: tusId,
            tus_type: tusType,
            tus_extension: tusExtension,
          }

          // Add description as data if the field tus-description exists.
          if (document.getElementById('tus-description') !== null && document.getElementById('tus-description').value !== '') {
            parameters['description'] = document.getElementById('tus-description').value;
          }

          $j.ajax({
            url: e.target.dataset['url'],
            type: "POST",
            data: parameters,
            success: function(data) {
            },
            error: function(jqXHR) {
              alert('Attaching Upload Failed');
            },
          });
        },
      });

      upload.findPreviousUploads().then(function (previousUploads) {
        // Found previous uploads so we select the first one.
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0])
        }

        upload.start()
      });

    });
  }
}

window.initializeTus = initializeTus;